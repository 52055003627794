// /**
//  * Provides a date picker for Final Forms (using https://github.com/airbnb/react-dates)
//  *
//  * NOTE: If you are using this component inside BookingDatesForm,
//  * you should convert value.date to start date and end date before submitting it to API
//  */
import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';

import { useConfiguration } from '../../context/configurationContext';
import { ValidationError } from '../../components';

import DateInput from './DateInput';
import css from './FieldDateInput.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const handleChange = (parentOnChange, inputOnChange) => value => {
  if (parentOnChange) {
    parentOnChange(value);
  }
  inputOnChange(value);
};

class FieldDateInputComponent extends Component {
  render() {
    const {
      className,
      rootClassName,
      id,
      label,
      showLabelAsDisabled,
      input,
      meta,
      useMobileMargins,
      showErrorMessage,
      onChange: parentOnChange,
      placeholderText,
      busyAuthor,
      ...rest
    } = this.props;

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { touched, invalid, error } = meta;
    const value = input.value;
    const dateIsValid = value && value.date instanceof Date;
    const hasError = touched && invalid && error;

    const inputClasses = classNames({
      [css.pickerSuccess]: dateIsValid,
      [css.pickerError]: hasError,
    });

    const { onBlur, onFocus, onChange: inputOnChange, type, checked, ...restOfInput } = input;
    const inputProps = {
      onBlur: input.onBlur,
      onFocus: input.onFocus,
      onChange: handleChange(parentOnChange, inputOnChange),
      useMobileMargins,
      id,
      readOnly: typeof window !== 'undefined' && window.innerWidth < MAX_MOBILE_SCREEN_WIDTH,
      placeholderText: placeholderText,
      busyAuthor,
      ...restOfInput,
      ...rest,
    };
    const classes = classNames(rootClassName || css.fieldRoot, className);
    const errorClasses = classNames({ [css.mobileMargins]: useMobileMargins });

    return (
      <div className={classes}>
        {label ? (
          <label
            className={classNames({
              [css.mobileMargins]: useMobileMargins,
              [css.labelDisabled]: showLabelAsDisabled,
            })}
            htmlFor={id}
          >
            {label}
          </label>
        ) : null}
        <DateInput className={inputClasses} {...inputProps} />
        {showErrorMessage ? <ValidationError className={errorClasses} fieldMeta={meta} /> : null}
      </div>
    );
  }
}

FieldDateInputComponent.defaultProps = {
  className: null,
  rootClassName: null,
  useMobileMargins: false,
  showErrorMessage: true,
  id: null,
  label: null,
  showLabelAsDisabled: false,
  placeholderText: null,
  onChange: null,
  busyAuthor: false,
  adsListing: false,
};

FieldDateInputComponent.propTypes = {
  className: string,
  rootClassName: string,
  useMobileMargins: bool,
  showErrorMessage: bool,
  id: string,
  label: string,
  showLabelAsDisabled: bool,
  placeholderText: string,
  input: object.isRequired,
  meta: object.isRequired,
  onChange: func,
  busyAuthor: bool,
  adsListing: bool,
};

const FieldDateInput = props => {
  const config = useConfiguration();
  const { isOutsideRange, firstDayOfWeek, busyAuthor, author, adsListing, ...rest } = props;

  const unavailableDates = author?.attributes?.profile?.publicData?.unavailableDates || [];
  const continuousDays = author?.attributes?.profile?.publicData?.continuousDays || 0;

  const isOutsideRangeWithSlash = day => {
    const today = moment().startOf('day');
    if (day.isBefore(today)) {
      return true;
    }
    return isOutsideRange ? isOutsideRange(day) : defaultIsOutSideRange(day);
  };

  const defaultIsOutSideRange = day => {
    const endOfRange = 365;
    return !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'));
  };

  const isDayBlocked = day => {
    const today = moment().startOf('day');

    // If adsListing is true, only block current day and next day
    if (adsListing) {
      return day.isSame(today, 'day') || day.isSame(today.clone().add(1, 'day'), 'day');
    }

    // Original blocking logic when adsListing is false
    const isUnavailable = unavailableDates.some(({ startDate, endDate }) => {
      const start = moment(startDate);
      const end = moment(endDate);
      return (
        (start.isSameOrAfter(today) || end.isSameOrAfter(today)) &&
        day.isBetween(start, end, 'day', '[]')
      );
    });

    // Block continuousDays from today
    const isWithinContinuousDays =
      continuousDays > 0 &&
      day.isBetween(today, today.clone().add(continuousDays - 1, 'days'), 'day', '[]');

    return isUnavailable || isWithinContinuousDays;
  };

  const renderDayContents = day => {
    const dayMoment = moment(day);
    const isBlocked = isDayBlocked(dayMoment);
    const isPastDay = dayMoment.isBefore(moment().startOf('day')); // Check if the day is in the past

    const className = classNames({
      [css.dayWithSlash]: isBlocked && !isPastDay, // Apply slash only if the blocked day is not in the past
    });

    return (
      <div className={className}>
        <span>{dayMoment.format('D')}</span>
        {isBlocked && !isPastDay && <div className={css.slash}></div>}
      </div>
    );
  };

  const defaultFirstDayOfWeek = config.localization.firstDayOfWeek;

  return (
    <Field
      component={FieldDateInputComponent}
      isOutsideRange={isOutsideRangeWithSlash}
      isDayBlocked={isDayBlocked}
      firstDayOfWeek={firstDayOfWeek || defaultFirstDayOfWeek}
      renderDayContents={renderDayContents}
      busyAuthor={busyAuthor}
      adsListing={adsListing}
      {...rest}
    />
  );
};

export { DateInput };
export default FieldDateInput;
