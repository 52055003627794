import React from 'react';
import loadable from '@loadable/component';

import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';
import PreviewResolverPage from '../containers/PreviewResolverPage/PreviewResolverPage';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'));
const AvailabilityPage = loadable(() => import(/* webpackChunkName: "AvailabilityPage" */ '../containers/AvailabilityPage/AvailabilityPage'));
const BlogMainPage = loadable(() => import(/* webpackChunkName: "BlogMainPage" */ '../containers/BlogPages/BlogMainPage'));
const BlogPageOne = loadable(() => import(/* webpackChunkName: "BlogPageOne" */ '../containers/BlogPages/Blog1/Blog1'));
const BlogPageTwo = loadable(() => import(/* webpackChunkName: "BlogPageTwo" */ '../containers/BlogPages/Blog2/Blog2'));
const BlogPageThree = loadable(() => import(/* webpackChunkName: "BlogPageThree" */ '../containers/BlogPages/Blog3/Blog3'));
const BlogPageFour = loadable(() => import(/* webpackChunkName: "BlogPageFour" */ '../containers/BlogPages/Blog4/Blog4'));
const BlogPageFive = loadable(() => import(/* webpackChunkName: "BlogPageFive" */ '../containers/BlogPages/Blog5/Blog5'));
const BlogPageSix = loadable(() => import(/* webpackChunkName: "BlogPageSix" */ '../containers/BlogPages/Blog6/Blog6'));
const BlogPageSeven = loadable(() => import(/* webpackChunkName: "BlogPageSeven" */ '../containers/BlogPages/Blog7/Blog7'));
const BlogPageEight = loadable(() => import(/* webpackChunkName: "BlogPageEight" */ '../containers/BlogPages/Blog8/Blog8'));
const BlogPageNine = loadable(() => import(/* webpackChunkName: "BlogPageNine" */ '../containers/BlogPages/Blog9/Blog9'));
const BlogPageTen = loadable(() => import(/* webpackChunkName: "BlogPageTen" */ '../containers/BlogPages/Blog10/Blog10'));
const BlogPageEleven = loadable(() => import(/* webpackChunkName: "BlogPageEleven" */ '../containers/BlogPages/Blog11/Blog11'));
const BlogPageTwelve = loadable(() => import(/* webpackChunkName: "BlogPageTwelve" */ '../containers/BlogPages/Blog12/Blog12'));
const BlogPageThirteen = loadable(() => import(/* webpackChunkName: "BlogPageThirteen" */ '../containers/BlogPages/Blog13/Blog13'));
const BlogPageFourteen = loadable(() => import(/* webpackChunkName: "BlogPageFourteen" */ '../containers/BlogPages/Blog14/Blog14'));
const BlogPageFifteen = loadable(() => import(/* webpackChunkName: "BlogPageFifteen" */ '../containers/BlogPages/Blog15/Blog15'));
const BlogPageSixteen = loadable(() => import(/* webpackChunkName: "BlogPageSixteen" */ '../containers/BlogPages/Blog16/Blog16'));
const BlogPageSeventeen = loadable(() => import(/* webpackChunkName: "BlogPageSeventeen" */ '../containers/BlogPages/Blog17/Blog17'));
const BlogPageEighteen = loadable(() => import(/* webpackChunkName: "BlogPageEighteen" */ '../containers/BlogPages/Blog18/Blog18'));
const BlogPageNineteen = loadable(() => import(/* webpackChunkName: "BlogPageNineteen" */ '../containers/BlogPages/Blog19/Blog19'));
const BlogPageTwenty = loadable(() => import(/* webpackChunkName: "BlogPageTwenty" */ '../containers/BlogPages/Blog20/Blog20'));
const BlogPageTwentyOne = loadable(() => import(/* webpackChunkName: "BlogPageTwentyOne" */ '../containers/BlogPages/Blog21/Blog21'));
const BlogPageTwentyTwo = loadable(() => import(/* webpackChunkName: "BlogPageTwentyTwo" */ '../containers/BlogPages/Blog22/Blog22'));
const BlogPageTwentyThree = loadable(() => import(/* webpackChunkName: "BlogPageTwentyThree" */ '../containers/BlogPages/Blog23/Blog23'));
const BlogPageTwentyFour = loadable(() => import(/* webpackChunkName: "BlogPageTwentyFour" */ '../containers/BlogPages/Blog24/Blog24'));
const BlogPageTwentyFive = loadable(() => import(/* webpackChunkName: "BlogPageTwentyFive" */ '../containers/BlogPages/Blog25/Blog25'));
const BlogPageTwentySix = loadable(() => import(/* webpackChunkName: "BlogPageTwentySix" */ '../containers/BlogPages/Blog26/Blog26'));
const BlogPageTwentySeven = loadable(() => import(/* webpackChunkName: "BlogPageTwentySeven" */ '../containers/BlogPages/Blog27/Blog27'));
const BlogPageTwentyEight =  loadable(() => import(/* webpackChunkName: "BlogPageTwentyEight" */ '../containers/BlogPages/Blog28/Blog28'));
const BlogPageTwentyNine =  loadable(() => import(/* webpackChunkName: "BlogPageTwentyNine" */ '../containers/BlogPages/Blog29/Blog29'));
const BlogPageThirty =  loadable(() => import(/* webpackChunkName: "BlogPageThirty" */ '../containers/BlogPages/Blog30/Blog30'));
const BlogPageThirtyOne =  loadable(() => import(/* webpackChunkName: "BlogPageThirtyOne" */ '../containers/BlogPages/Blog31/Blog31'));
const BlogPageThirtyTwo =  loadable(() => import(/* webpackChunkName: "BlogPageThirtyTwo" */ '../containers/BlogPages/Blog32/Blog32'));
const BlogPageThirtyThree =  loadable(() => import(/* webpackChunkName: "BlogPageThirtyThree" */ '../containers/BlogPages/Blog33/Blog33'));
const BlogPageThirtyFour =  loadable(() => import(/* webpackChunkName: "BlogPageThirtyFour" */ '../containers/BlogPages/Blog34/Blog34'));
const BlogPageThirtyFive =  loadable(() => import(/* webpackChunkName: "BlogPageThirtyFive" */ '../containers/BlogPages/Blog35/Blog35'));
const BlogPageThirtySix =  loadable(() => import(/* webpackChunkName: "BlogPageThirtySix" */ '../containers/BlogPages/Blog36/Blog36'));
const BlogPageThirtySeven =  loadable(() => import(/* webpackChunkName: "BlogPageThirtySeven" */ '../containers/BlogPages/Blog37/Blog37'));
const BlogPageThirtyEight =  loadable(() => import(/* webpackChunkName: "BlogPageThirtyEight" */ '../containers/BlogPages/Blog38/Blog38'));
const BlogPageThirtyNine = loadable(() => import(/* webpackChunkName: "BlogPageThirtyNine" */ '../containers/BlogPages/Blog39/Blog39'));
const BlogPageFourty = loadable(() => import(/* webpackChunkName: "BlogPageFourty" */ '../containers/BlogPages/Blog40/Blog40'));
const BlogPageFourtyOne = loadable(() => import(/* webpackChunkName: "BlogPageFourtyOne" */ '../containers/BlogPages/Blog41/Blog41'));
const BlogPageFourtyTwo = loadable(() => import(/* webpackChunkName: "BlogPageFourtyTwo" */ '../containers/BlogPages/Blog42/Blog42'));
const BlogPageFourtyThree = loadable(() => import(/* webpackChunkName: "BlogPageFourtyThree" */ '../containers/BlogPages/Blog43/Blog43'));
const BlogPageFourtyFour = loadable(() => import(/* webpackChunkName: "BlogPageFourtyFour" */ '../containers/BlogPages/Blog44/Blog44'));
const BlogPageFourtyFive = loadable(() => import(/* webpackChunkName: "BlogPageFourtyFive" */ '../containers/BlogPages/Blog45/Blog45'));
const BlogPageFourtySix = loadable(() => import(/* webpackChunkName: "BlogPageFourtySix" */ '../containers/BlogPages/Blog46/Blog46'));
const BlogPageFourtySeven = loadable(() => import(/* webpackChunkName: "BlogPageFourtySeven" */ '../containers/BlogPages/Blog47/Blog47'));
const BlogPageFourtyEight = loadable(() => import(/* webpackChunkName: "BlogPageFourtyEight" */ '../containers/BlogPages/Blog48/Blog48'));
const BlogPageFourtyNine = loadable(() => import(/* webpackChunkName: "BlogPageFourtyNine" */ '../containers/BlogPages/Blog49/Blog49'));
const BlogPageFifty = loadable(() => import(/* webpackChunkName: "BlogPageFifty" */ '../containers/BlogPages/Blog50/Blog50'));
const BlogPageFiftyOne = loadable(() => import(/* webpackChunkName: "BlogPageFiftyOne" */ '../containers/BlogPages/Blog51/Blog51'));
const BlogPageFiftyTwo = loadable(() => import(/* webpackChunkName: "BlogPageFiftyTwo" */ '../containers/BlogPages/Blog52/Blog52'));
const BlogPageFiftyThree = loadable(() => import(/* webpackChunkName: "BlogPageFiftyThree" */ '../containers/BlogPages/Blog53/Blog53'));
const BlogPageFiftyFour = loadable(() => import(/* webpackChunkName: "BlogPageFiftyFour" */ '../containers/BlogPages/Blog54/Blog54'));
const BlogPageFiftyFive = loadable(() => import(/* webpackChunkName: "BlogPageFiftyFive" */ '../containers/BlogPages/Blog55/Blog55'));
const BlogPageFiftySix = loadable(() => import(/* webpackChunkName: "BlogPageFiftySix" */ '../containers/BlogPages/Blog56/Blog56'));
const BlogPageFiftySeven = loadable(() => import(/* webpackChunkName: "BlogPageFiftySeven" */ '../containers/BlogPages/Blog57/Blog57'));
const BlogPageFiftyEight = loadable(() => import(/* webpackChunkName: "BlogPageFiftyEight" */ '../containers/BlogPages/Blog58/Blog58'));
const BlogPageFiftyNine = loadable(() => import(/* webpackChunkName: "BlogPageFiftyNine" */ '../containers/BlogPages/Blog59/Blog59'));
const BlogPageSixty = loadable(() => import(/* webpackChunkName: "BlogPageSixty" */ '../containers/BlogPages/Blog60/Blog60'));
const BlogPageSixtyOne = loadable(() => import(/* webpackChunkName: "BlogPageSixtyOne" */ '../containers/BlogPages/Blog61/Blog61'));
const BlogPageSixtyTwo = loadable(() => import(/* webpackChunkName: "BlogPageSixtyTwo" */ '../containers/BlogPages/Blog62/Blog62'));
const BlogPageSixtyThree = loadable(() => import(/* webpackChunkName: "BlogPageSixtyThree" */ '../containers/BlogPages/Blog63/Blog63'));
const BlogPageSixtyFour = loadable(() => import(/* webpackChunkName: "BlogPageSixtyFour" */ '../containers/BlogPages/Blog64/Blog64'));
const BlogPageSixtyFive = loadable(() => import(/* webpackChunkName: "BlogPageSixtyFive" */ '../containers/BlogPages/Blog65/Blog65'));
const BlogPageSixtySix = loadable(() => import(/* webpackChunkName: "BlogPageSixtySix" */ '../containers/BlogPages/Blog66/Blog66'));
const BlogPageSixtySeven = loadable(() => import(/* webpackChunkName: "BlogPageSixtySeven" */ '../containers/BlogPages/Blog67/Blog67'));
const BlogPageSixtyEight = loadable(() => import(/* webpackChunkName: "BlogPageSixtyEight" */ '../containers/BlogPages/Blog68/Blog68'));
const BlogPageSixtyNine = loadable(() => import(/* webpackChunkName: "BlogPageSixtyNine" */ '../containers/BlogPages/Blog69/Blog69'));
const BlogPageSeventy = loadable(() => import(/* webpackChunkName: "BlogPageSeventy" */ '../containers/BlogPages/Blog70/Blog70'));
const BlogPageSeventyOne = loadable(() => import(/* webpackChunkName: "BlogPageSeventyOne" */ '../containers/BlogPages/Blog71/Blog71'));
const BlogPageSeventyTwo = loadable(() => import(/* webpackChunkName: "BlogPageSeventyTwo" */ '../containers/BlogPages/Blog72/Blog72'));
const BlogPageSeventyThree = loadable(() => import(/* webpackChunkName: "BlogPageSeventyThree" */ '../containers/BlogPages/Blog73/Blog73'));
const BlogPageSeventyFour = loadable(() => import(/* webpackChunkName: "BlogPageSeventyFour" */ '../containers/BlogPages/Blog74/Blog74'));
const BlogPageSeventyFive = loadable(() => import(/* webpackChunkName: "BlogPageSeventyFive" */ '../containers/BlogPages/Blog75/Blog75'));
const BlogPageSeventySix = loadable(() => import(/* webpackChunkName: "BlogPageSeventySix" */ '../containers/BlogPages/Blog76/Blog76'));
const BlogPageSeventySeven = loadable(() => import(/* webpackChunkName: "BlogPageSeventySeven" */ '../containers/BlogPages/Blog77/Blog77'));
const BlogPageSeventyEight = loadable(() => import(/* webpackChunkName: "BlogPageSeventyEight" */ '../containers/BlogPages/Blog78/Blog78'));
const BlogPageSeventyNine = loadable(() => import(/* webpackChunkName: "BlogPageSeventyNine" */ '../containers/BlogPages/Blog79/Blog79'));
const BlogPageEighty = loadable(() => import(/* webpackChunkName: "BlogPageEighty" */ '../containers/BlogPages/Blog80/Blog80'));
const BlogPageEightyOne = loadable(() => import(/* webpackChunkName: "BlogPageEightyOne" */ '../containers/BlogPages/Blog81/Blog81'));
const BlogPageEightyTwo = loadable(() => import(/* webpackChunkName: "BlogPageEightyTwo" */ '../containers/BlogPages/Blog82/Blog82'));
const BlogPageEightyThree = loadable(() => import(/* webpackChunkName: "BlogPageEightyThree" */ '../containers/BlogPages/Blog83/Blog83'));
const BlogPageEightyFour = loadable(() => import(/* webpackChunkName: "BlogPageEightyFour" */ '../containers/BlogPages/Blog84/Blog84'));
const BlogPageEightyFive = loadable(() => import(/* webpackChunkName: "BlogPageEightyFive" */ '../containers/BlogPages/Blog85/Blog85'));
const BlogPageEightySix = loadable(() => import(/* webpackChunkName: "BlogPageEightySix" */ '../containers/BlogPages/Blog86/Blog86'));
const BlogPageEightySeven = loadable(() => import(/* webpackChunkName: "BlogPageEightySeven" */ '../containers/BlogPages/Blog87/Blog87'));
const BlogPageEightyEight = loadable(() => import(/* webpackChunkName: "BlogPageEightyEight" */ '../containers/BlogPages/Blog88/Blog88'));
const BlogPageEightyNine = loadable(() => import(/* webpackChunkName: "BlogPageEightyNine" */ '../containers/BlogPages/Blog89/Blog89'));
const BlogPageNinty = loadable(() => import(/* webpackChunkName: "BlogPageNinty" */ '../containers/BlogPages/Blog90/Blog90'));
const BlogPageNintyOne = loadable(() => import(/* webpackChunkName: "BlogPageNintyOne" */ '../containers/BlogPages/Blog91/Blog91'));
const BlogPageNintyTwo = loadable(() => import(/* webpackChunkName: "BlogPageNintyTwo" */ '../containers/BlogPages/Blog92/Blog92'));
const BlogPageNintyThree = loadable(() => import(/* webpackChunkName: "BlogPageNintyThree" */ '../containers/BlogPages/Blog93/Blog93'));
const BlogPageNintyFour = loadable(() => import(/* webpackChunkName: "BlogPageNintyFour" */ '../containers/BlogPages/Blog94/Blog94'));
const BlogPageNintyFive = loadable(() => import(/* webpackChunkName: "BlogPageNintyFive" */ '../containers/BlogPages/Blog95/Blog95'));
const BlogPageNintySix = loadable(() => import(/* webpackChunkName: "BlogPageNintySix" */ '../containers/BlogPages/Blog96/Blog96'));
const BlogPageNintySeven = loadable(() => import(/* webpackChunkName: "BlogPageNintySeven" */ '../containers/BlogPages/Blog97/Blog97'));
const BlogPageNintyEight = loadable(() => import(/* webpackChunkName: "BlogPageNintyEight" */ '../containers/BlogPages/Blog98/Blog98'));
const BlogPageNintyNine = loadable(() => import(/* webpackChunkName: "BlogPageNintyNine" */ '../containers/BlogPages/Blog99/Blog99'));
const BlogPageHundred = loadable(() => import(/* webpackChunkName: "BlogPageHundred" */ '../containers/BlogPages/Blog100/Blog100'));
const BlogPageHundredOne = loadable(() => import(/* webpackChunkName: "BlogPageHundredOne" */ '../containers/BlogPages/Blog101/Blog101'));
const BlogPageHundredTwo = loadable(() => import(/* webpackChunkName: "BlogPageHundredTwo" */ '../containers/BlogPages/Blog102/Blog102'));
const ContactPage = loadable(() => import(/* webpackChunkName: "BlogMainPage" */ '../containers/ContactPage/ContactPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage'));
const CMSPage = loadable(() => import(/* webpackChunkName: "CMSPage" */ '../containers/CMSPage/CMSPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage'));
const ListingPageCoverPhoto = loadable(() => import(/* webpackChunkName: "ListingPageCoverPhoto" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCoverPhoto'));
const ListingPageCarousel = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCarousel'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'));
const PayoutsPage = loadable(() => import(/* webpackChunkName: "PayoutsPage" */ '../containers/PayoutsPage/PayoutsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPageWithMap = loadable(() => import(/* webpackChunkName: "SearchPageWithMap" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithMap'));
const SearchPageWithGrid = loadable(() => import(/* webpackChunkName: "SearchPageWithGrid" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithGrid'));
const ListingInfoPage = loadable(() => import(/* webpackChunkName: "ListingInfoPage" */ '../containers/ListingInfoPage/ListingInfoPage'));
const LocationPage = loadable(() => import(/* webpackChunkName: "SeoGlendale" */ /* webpackPrefetch: true */  '../containers/SEOPages/LocationPage'));
const SubLocationPage = loadable(() => import(/* webpackChunkName: "SubLocationPage" */ /* webpackPrefetch: true */ '../containers/SEOPages/SubLocationPage'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage'));
const VeganDessertPage = loadable(() => import(/* webpackChunkName: "VeganDessertPage" */ '../containers/VeganDessertPage/VeganDessertPage'));
const CustomCookiesPage = loadable(() => import(/* webpackChunkName: "CustomCookiesPage" */ '../containers/CustomCookiesPage/CustomCookiesPage'));
const AdminDashboardPage = loadable(() => import(/* webpackChunkName: "CustomCookiesPage" */ '../containers/AdminDashboardPage/AdminDashboardPage'));
const SellerOnboardPage = loadable(() => import(/* webpackChunkName: "SellerOnboardPage" */ '../containers/SellerOnboardPage/SellerOnboardPage'));
const SellerQuickOnboardPage = loadable(() => import(/* webpackChunkName: "SellerQuickOnboardPage" */ '../containers/SellerQuickOnboardPage/SellerQuickOnboardPage'));

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = (layoutConfig) => {
  const SearchPage = layoutConfig?.searchPage?.variantType === 'map' 
    ? SearchPageWithMap 
    : SearchPageWithGrid;
  const ListingPage = layoutConfig?.listingPage?.variantType === 'carousel' 
    ? ListingPageCarousel 
    : ListingPageCoverPhoto;
  
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/availability',
      name: 'AvailabilityPage',
      auth: true,
      authPage: 'LoginPage',
      component: AvailabilityPage,
    },
    {
      path: '/blogs',
      name: 'BlogMainPage',
      component: BlogMainPage,
    },
    {
      path: '/blogs/sweettooth-gourmet-dessert-delivery',
      name: 'BlogPageOne',
      component: BlogPageOne,
    },
    {
      path: '/blogs/ultimate-baby-shower-checklist',
      name: 'BlogPageTwo',
      component: BlogPageTwo,
    },
    {
      path: '/blogs/planning-your-babys-first-birthday-party',
      name: 'BlogPageThree',
      component: BlogPageThree,
    },
    {
      path: '/blogs/personalized-birthday-gift',
      name: 'BlogPageFour',
      component: BlogPageFour,
    },
    {
      path: '/blogs/host-a-girls-night',
      name: 'BlogPageFive',
      component: BlogPageFive,
    },
    {
      path: '/blogs/surprise-birthday-party',
      name: 'BlogPageSix',
      component: BlogPageSix,
    },
    {
      path: '/blogs/hosting-a-dinner-party',
      name: 'BlogPageSeven',
      component: BlogPageSeven,
    },
    {
      path: '/blogs/ultimate-romantic-picnic-date',
      name: 'BlogPageEight',
      component: BlogPageEight,
    },
    {
      path: '/blogs/first-date-idea',
      name: 'BlogPageNine',
      component: BlogPageNine,
    },
    {
      path: '/blogs/gifts-for-best-friends',
      name: 'BlogPageTen',
      component: BlogPageTen,
    },
    {
      path: '/blogs/dessert-table-delights',
      name: 'BlogPageEleven',
      component: BlogPageEleven,
    },
    {
      path: '/blogs/gifts-for-mothers-day',
      name: 'BlogPageTwelve',
      component: BlogPageTwelve,
    },
    {
      path: '/blogs/best-restaurants-in-la',
      name: 'BlogPageThirteen',
      component: BlogPageThirteen,
    },
    {
      path: '/blogs/best-restaurants-in-orange-county',
      name: 'BlogPageFourteen',
      component: BlogPageFourteen,
    },
    {
      path: '/blogs/best-fiftieth-birthday-cake',
      name: 'BlogPageFifteen',
      component: BlogPageFifteen,
    },
    {
      path: '/blogs/best-baby-shower-desserts',
      name: 'BlogPageSixteen',
      component: BlogPageSixteen,
    },
    {
      path: '/blogs/gender-reveal-food-ideas',
      name: 'BlogPageSeventeen',
      component: BlogPageSeventeen,
    },
    {
      path: '/blogs/wedding-dessert-table-ideas',
      name: 'BlogPageEighteen',
      component: BlogPageEighteen,
    },
    {
      path: '/blogs/romantic-marriage-proposal-ideas',
      name: 'BlogPageNineteen',
      component: BlogPageNineteen,
    },
    {
      path: '/blogs/company-holiday-party-ideas',
      name: 'BlogPageTwenty',
      component: BlogPageTwenty,
    },
    {
      path: '/blogs/stripe-transforms-online-payments',
      name: 'BlogPageTwentyOne',
      component: BlogPageTwentyOne,
    },
    {
      path: '/blogs/plan-perfect-movie-night',
      name: 'BlogPageTwentyTwo',
      component: BlogPageTwentyTwo,
    },
    {
      path: '/blogs/planning-baptism-and-christening-party',
      name: 'BlogPageTwentyTwo',
      component: BlogPageTwentyThree,
    },
    {
      path: '/blogs/decadent-delights',
      name: 'BlogPageTwentyTwo',
      component: BlogPageTwentyFour,
    },
    {
      path: '/blogs/gifts-for-boyfriends-mom',
      name: 'BlogPageTwentyFive',
      component: BlogPageTwentyFive,
    },
    {
      path: '/blogs/magic-of-custom-cakes-for-birthdays',
      name: 'BlogPageTwentySix',
      component: BlogPageTwentySix,
    },
    {
      path: '/blogs/freeze-cheesecake-preserving-dessert',
      name: 'BlogPageTwentySeven',
      component: BlogPageTwentySeven,
    },
    {
      path: '/blogs/dog-birthday-cakes',
      name: 'BlogPageTwentyEight',
      component: BlogPageTwentyEight,
    },
    {
      path: '/blogs/easy-kids-birthday-cake-receipes',
      name: 'BlogPageTwentyNine',
      component: BlogPageTwentyNine,
    },
    {
      path: '/blogs/gourmet-delights-nationwide-cake-delivery',
      name: 'BlogPageThirty',
      component: BlogPageThirty,
    },
    {
      path: '/blogs/find-the-best-desserts-in-los-angeles',
      name: 'BlogPageThirtyOne',
      component: BlogPageThirtyOne,
    },
    {
      path: '/blogs/best-french-macaron-in-la',
      name: 'BlogPageThirtyTwo',
      component: BlogPageThirtyTwo,
    },
    {
      path: '/blogs/recipe-for-chocolate-filled-cookie-croissant',
      name: 'BlogPageThirtyThree',
      component: BlogPageThirtyThree,
    },
    {
      path: '/blogs/ten-best-vegan-dessert-bakeries-in-la',
      name: 'BlogPageThirtyFour',
      component: BlogPageThirtyFour,
    },
    {
      path: '/blogs/best-cheesecake-in-la-march2024',
      name: 'BlogPageThirtyFive',
      component: BlogPageThirtyFive,
    },
    {
      path: '/blogs/best-chocolate-cake-in-la-guide',
      name: 'BlogPageThirtySix',
      component: BlogPageThirtySix,
    },
    {
      path: '/blogs/best-custom-cookies-instagram-designs',
      name: 'BlogPageThirtySeven',
      component: BlogPageThirtySeven,
    },
    {
      path: '/blogs/best-dessert-catering-in-la',
      name: 'BlogPageThirtyEight',
      component: BlogPageThirtyEight,
    },
    {
      path: '/blogs/cheese-lovers-guide-to-kunafa',
      name: 'BlogPageThirtyNine',
      component: BlogPageThirtyNine,
    },
    {
      path: '/blogs/best-vegan-desserts-at-top-vegan-bakeries',
      name: 'BlogPageFourty',
      component: BlogPageFourty,
    },
    {
      path: '/blogs/bento-cake-ideas-for-lunchbox',
      name: 'BlogPageFourtyOne',
      component: BlogPageFourtyOne,
    },
    {
      path: '/blogs/best-vegan-sweet-bakeries-in-los-angeles',
      name: 'BlogPageFourtyTwo',
      component: BlogPageFourtyTwo,
    },
    {
      path: '/blogs/best-baklava-in-los-angeles',
      name: 'BlogPageFourtyThree',
      component: BlogPageFourtyThree,
    },
    {
      path: '/blogs/transporting-cake-in-car-and-airplane',
      name: 'BlogPageFourtyFour',
      component: BlogPageFourtyFour,
    },
    {
      path: '/blogs/best-dessert-destinations-in-orange-county',
      name: 'BlogPageFourtyFive',
      component: BlogPageFourtyFive,
    },
    {
      path: '/blogs/best-farmers-market-in-la',
      name: 'BlogPageFourtySix',
      component: BlogPageFourtySix,
    },
    {
      path: '/blogs/cake-portion-and-size-chart-guide',
      name: 'BlogPageFourtySeven',
      component: BlogPageFourtySeven,
    },
    {
      path: '/blogs/list-of-best-wedding-venues-in-la',
      name: 'BlogPageFourtyEight',
      component: BlogPageFourtyEight,
    },
    {
      path: '/blogs/best-birthday-party-venues-in-los-angeles',
      name: 'BlogPageFourtyNine',
      component: BlogPageFourtyNine,
    },
    {
      path: '/blogs/top-wedding-venues-in-los-angeles',
      name: 'BlogPageFifty',
      component: BlogPageFifty,
    },
    {
      path: '/blogs/how-to-make-ice-cream-cone-cake-pops',
      name: 'BlogPageFiftyOne',
      component: BlogPageFiftyOne,
    },
    {
      path: '/blogs/event-and-party-venues-for-rent-in-los-angeles',
      name: 'BlogPageFiftyTwo',
      component: BlogPageFiftyTwo,
    },
    {
      path: '/blogs/best-farmers-market-in-orange-county',
      name: 'BlogPageFiftyThree',
      component: BlogPageFiftyThree,
    },
    {
      path: '/blogs/vegan-dessert-in-plant-based-sector',
      name: 'BlogPageFiftyFour',
      component: BlogPageFiftyFour,
    },
    {
      path: '/blogs/ice-cream-shops-in-la-a-creamery-lovers-guide',
      name: 'BlogPageFiftyFive',
      component: BlogPageFiftyFive,
    },
    {
      path: '/blogs/perfect-gift-for-mom',
      name: 'BlogPageFiftySix',
      component: BlogPageFiftySix,
    },
    {
      path: '/blogs/viral-burn-away-cake-trend-tiktok',
      name: 'BlogPageFiftySeven',
      component: BlogPageFiftySeven,
    },
    {
      path: '/blogs/charm-of-coquette-cake-for-your-next-party',
      name: 'BlogPageFiftyEight',
      component: BlogPageFiftyEight,
    },
    {
      path: '/blogs/vintage-hear-cake-bakery-style',
      name: 'BlogPageFiftyNine',
      component: BlogPageFiftyNine,
    },
    {
      path: '/blogs/gummy-mango-candy-viral-tiktok',
      name: 'BlogPageSixty',
      component: BlogPageSixty,
    },
    {
      path: '/blogs/graduation-success-sugar-cookies-2024',
      name: 'BlogPageSixtyOne',
      component: BlogPageSixtyOne,
    },
    {
      path: '/blogs/discover-most-romantic-restaurants-in-la',
      name: 'BlogPageSixtyTwo',
      component: BlogPageSixtyTwo,
    },
    {
      path: '/blogs/top-10-best-custom-cookies',
      name: 'BlogPageSixtyThree',
      component: BlogPageSixtyThree,
    },
    {
      path: '/blogs/ultimate-guide-on-how-to-make-baby-shower-favors',
      name: 'BlogPageSixtyFour',
      component: BlogPageSixtyFour,
    },
    {
      path: '/blogs/la-county-fair-2024',
      name: 'BlogPageSixtyFive',
      component: BlogPageSixtyFive,
    },
    {
      path: '/blogs/celebrating-with-graduation-cookies',
      name: 'BlogPageSixtySix',
      component: BlogPageSixtySix,
    },
    {
      path: '/blogs/magic-of-baby-gender-reveal-cookies',
      name: 'BlogPageSixtySeven',
      component: BlogPageSixtySeven,
    },
    {
      path: '/blogs/top-brunch-spots-in-la',
      name: 'BlogPageSixtyEight',
      component: BlogPageSixtyEight,
    },
    {
      path: '/blogs/guide-to-disneyland-park-resort',
      name: 'BlogPageSixtyNine',
      component: BlogPageSixtyNine,
    },
    {
      path: '/blogs/celebrate-mothers-day-in-la',
      name: 'BlogPageSeventy',
      component: BlogPageSeventy,
    },
    {
      path: '/blogs/gateway-to-custom-cookies-in-orange-county',
      name: 'BlogPageSeventyOne',
      component: BlogPageSeventyOne,
    },
    {
      path: '/blogs/ultimate-guide-to-luxury-cakes-for-celebrations-2024',
      name: 'BlogPageSeventyTwo',
      component: BlogPageSeventyTwo,
    },
    {
      path: '/blogs/storing-sugar-cookies-for-lasting-long',
      name: 'BlogPageSeventyThree',
      component: BlogPageSeventyThree,
    },
    {
      path: '/blogs/tutorial-to-make-a-car-cake',
      name: 'BlogPageSeventyFour',
      component: BlogPageSeventyFour,
    },
    {
      path: '/blogs/baby-shark-themed-birthday-parties',
      name: 'BlogPageSeventyFive',
      component: BlogPageSeventyFive,
    },
    {
      path: '/blogs/how-custom-cookies-are-made',
      name: 'BlogPageSeventySix',
      component: BlogPageSeventySix,
    },
    {
      path: '/blogs/mermaid-cake-for-birthday-celebration',
      name: 'BlogPageSeventySeven',
      component: BlogPageSeventySeven,
    },
    {
      path: '/blogs/unleash-your-creativity-with-moana-cake',
      name: 'BlogPageSeventyEight',
      component: BlogPageSeventyEight,
    },
    {
      path: '/blogs/fathers-day-gift-for-2024',
      name: 'BlogPageSeventyNine',
      component: BlogPageSeventyNine,
    },
    {
      path: '/blogs/perfect-party-event-spaces-and-venue-in-los-angeles',
      name: 'BlogPageEighty',
      component: BlogPageEighty,
    },
    {
      path: '/blogs/fun-with-super-mario-party-supplies',
      name: 'BlogPageEightyOne',
      component: BlogPageEightyOne,
    },
    {
      path: '/blogs/the-best-party-supplies-store-in-los-angeles',
      name: 'BlogPageEightyTwo',
      component: BlogPageEightyTwo,
    },
    {
      path: '/blogs/ultimate-super-mario-birthday-cake-2024',
      name: 'BlogPageEightyThree',
      component: BlogPageEightyThree,
    },
    {
      path: '/blogs/creative-gender-reveal-perfect-party-idea',
      name: 'BlogPageEightyFour',
      component: BlogPageEightyFour,
    },
    {
      path: '/blogs/elevate-your-brand-with-custom-logo-cookies',
      name: 'BlogPageEightyFive',
      component: BlogPageEightyFive,
    },
    {
      path: '/blogs/fifteen-best-baby-shower-theme-ideas-2024',
      name: 'BlogPageEightySix',
      component: BlogPageEightySix,
    },
    {
      path: '/blogs/to-know-about-custom-cake-pricing',
      name: 'BlogPageEightySeven',
      component: BlogPageEightySeven,
    },
    {
      path: '/blogs/elevate-celebration-with-photo-cakes',
      name: 'BlogPageEightyEight',
      component: BlogPageEightyEight,
    },
    {
      path: '/blogs/personalize-your-celebration-guide-to-custom-cake-toppers',
      name: 'BlogPageEightyNine',
      component: BlogPageEightyNine,
    },
    {
      path: '/blogs/ultimate-guide-to-rooftop-birthday-dinners-in-los-angeles',
      name: 'BlogPageNinty',
      component: BlogPageNinty,
    },
    {
      path: '/blogs/celebrate-first-birthday-with-creative-theme-los-angeles',
      name: 'BlogPageNintyOne',
      component: BlogPageNintyOne,
    },
    {
      path: '/blogs/perfect-backyard-birthday-party-ideas-los-angeles',
      name: 'BlogPageNintyTwo',
      component: BlogPageNintyTwo,
    },
    {
      path: '/blogs/ultimate-guide-to-planning-perfect-birthday-dinner-los-angeles',
      name: 'BlogPageNintyThree',
      component: BlogPageNintyThree,
    },
    {
      path: '/blogs/ultimate-guide-to-rooftop-birthday-dinner-la-2024',
      name: 'BlogPageNintyFour',
      component: BlogPageNintyFour,
    },
    {
      path: '/blogs/tips-for-planning-the-perfect-adult-celebration',
      name: 'BlogPageNintyFive',
      component: BlogPageNintyFive,
    },
    {
      path: '/blogs/truth-behind-goody-bags',
      name: 'BlogPageNintySix',
      component: BlogPageNintySix,
    },
    {
      path: '/blogs/cost-of-decorated-cookies',
      name: 'BlogPageNintySeven',
      component: BlogPageNintySeven,
    },
    {
      path: '/blogs/ultimate-guide-to-finding-your-dream-wedding-venue-in-la',
      name: 'BlogPageNintyEight',
      component: BlogPageNintyEight,
    },
    {
      path: '/blogs/best-birthday-restaurant-dinners-in-orange-county-2024',
      name: 'BlogPageNintyNine',
      component: BlogPageNintyNine,
    },
    {
      path: '/blogs/best-baby-shower-cake-ideas',
      name: 'BlogPageHundred',
      component: BlogPageHundred,
    },
    {
      path: '/blogs/where-should-you-host-baby-shower-events',
      name: 'BlogPageHundredOne',
      component: BlogPageHundredOne,
    },
    {
      path: '/blogs/who-hosts-a-bridal-shower',
      name: 'BlogPageHundredTwo',
      component: BlogPageHundredTwo,
    },
    {
      path: '/p/contact-us',
      name: 'ContactPage',
      component: ContactPage,
    },
    {
      path: '/listing-details',
      name: 'ListingInfoPage',
      component: ListingInfoPage,
    },
    {
      path: '/p/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
      {
      path: '/admin-dashboard',
      name: 'AdminDashboardPage',
      auth: true,
      component: AdminDashboardPage,
      loadData: pageDataLoadingAPI.AdminDashboardPage.loadData,
    },

    {
      path: '/active-providers',
      name: 'ActiveProvidersPage',
      auth: true,
      component: AdminDashboardPage,
      
    },

    {
      path: '/inactive-providers',
      name: 'InactiveProvidersPage',
      auth: true,
      component: AdminDashboardPage,
      
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:slug/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/city/:location/:category',
      name: 'LocationPage',
      component: LocationPage,
      loadData: pageDataLoadingAPI.LocationPage.loadData,
    },
    {
      path: '/city/:location/:sublocation/:category',
      name: 'SubLocationPage',
      component: SubLocationPage,
      loadData: pageDataLoadingAPI.SubLocationPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/sell-on-sweet-tooth',
      name: 'SellerOnboardPage',
      component: SellerOnboardPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.SellerOnboardPage.loadData,
    },
    {
      path: '/selling-on-sweet-tooth',
      name: 'SellerQuickOnboardPage',
      component: SellerQuickOnboardPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.SellerQuickOnboardPage.loadData,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        const { tab } = props.params;
        return <NamedRedirect name="InboxPage" params={{ tab, subTab: 'transactions', sortFilter:'default' }} />;
      },
    },
    {
      path: '/inbox/:tab/:subTab/:sortFilter',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },    
    {
      path: '/payouts',
      name: 'PayoutsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PayoutsPage,
      loadData: pageDataLoadingAPI.PayoutsPage.loadData,
    },
    {
      path: '/order/:id/:transactionType',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) => {
        // This line had issues with commas and missing return statement
        return pageDataLoadingAPI.TransactionPage.loadData({ 
          ...params, 
          transactionRole: 'customer' 
        }, ...rest);
      },
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues
    },    
    {
      path: '/order/:id/:transactionType/details',
      name: 'OrderDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        return <NamedRedirect name="OrderDetailsPage" params={{ id: props.params?.id, transactionType: props.params?.transactionType }} />
      }
    },
    {
      path: '/sale/:id/:transactionType',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
    },
    {
      path: '/sale/:id/:transactionType/details',
      name: 'SaleDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        return <NamedRedirect name="SaleDetailsPage" params={{ id: props.params?.id, transactionType: props.params?.transactionType }} />;
      },
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
      loadData: pageDataLoadingAPI.TermsOfServicePage.loadData,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
      loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /preview endpoint
    {
      path: '/preview',
      name: 'PreviewResolverPage',
      component: PreviewResolverPage ,
    },
    {
      path: '/vegan-dessert',
      name: 'VeganDessertPage',
      component: VeganDessertPage,
    },
    {
      path: '/a/custom-cookies',
      name: 'CustomCookiesPage',
      component: CustomCookiesPage,
    },
  ];
};

export default routeConfiguration;
