import React, { useState } from 'react';
import { PrimaryButton } from '../../components';
import css from './PhoneNumberModal.module.css';

const PhoneNumberModal = ({ onClose, onConfirm, intl }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  // Handle input change - only allow numbers
  const handlePhoneChange = e => {
    const input = e.target.value;

    // Only allow digits and limit to 10 characters
    const numericInput = input.replace(/\D/g, '').slice(0, 10);

    setPhoneNumber(numericInput);

    // Clear error when user starts typing again
    if (error) setError('');
  };

  const handleSubmit = () => {
    // Check if empty
    if (!phoneNumber) {
      setError(
        intl.formatMessage({
          id: 'SignupForm.phoneRequired',
        })
      );
      return;
    }

    // Check if exactly 10 digits
    if (phoneNumber.length !== 10) {
      setError(
        intl.formatMessage({
          id: 'SignupForm.phoneInvalid',
        })
      );
      return;
    }

    // If validation passes
    setError('');
    onConfirm(phoneNumber);
  };

  const phonePlaceholder = intl.formatMessage({
    id: 'ContactDetailsForm.phonePlaceholder',
  });

  const phoneLabel = intl.formatMessage({
    id: 'ContactDetailsForm.phoneLabel',
  });

  return (
    <div className={css.modalBackdrop}>
      <div className={css.modalContent}>
        <h3>Enter Your Phone Number</h3>

        <div className={css.inputContainer}>
          <label htmlFor="phoneNumber" className={css.label}>
            {phoneLabel}
          </label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            className={css.phoneInput}
            placeholder={phonePlaceholder}
            value={phoneNumber}
            onChange={handlePhoneChange}
            pattern="[0-9]{10}"
            maxLength="10"
            aria-invalid={!!error}
            aria-describedby={error ? 'phone-error' : undefined}
          />
          {error && (
            <p id="phone-error" className={css.error}>
              {error}
            </p>
          )}
        </div>

        <div className={css.buttonGroup}>
          <PrimaryButton className={css.submitButton} onClick={handleSubmit}>
            Submit
          </PrimaryButton>
          <button className={css.cancelButton} onClick={onClose} type="button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberModal;
